<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<mat-form-field class="full-width" appearance="outline" i18n-appearance>
  <input
    type="text"
    matInput
    placeholder="Search financial plan tags"
    i18n-placeholder
    [formControl]="tagCtrl"
    [matAutocomplete]="autoTag"
  />
  <button
    mat-icon-button
    matSuffix
    aria-label="Add tag"
    i18n-aria-label
    (click)="addTagFromInput()"
  >
    <mat-icon i18n>add</mat-icon>
  </button>
  <mat-autocomplete
    #autoTag="matAutocomplete"
    (optionSelected)="addTag($event)"
  >
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
